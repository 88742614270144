.League {
  padding-top: 5%;
  padding-bottom: 5%;
  min-height: calc(100vh - 121px);
}

.League h1 {
  text-align: center;
  margin-bottom: 2em;
}

.League h2 {
  margin: 2em 0 1em;
  font-size: 1.4em;
  font-weight: bold;
}

.League h3 {
  text-transform: uppercase;
  margin-bottom: 1em;
  font-weight: 600;
}

.League .wrapper {
  max-width: 1200px;
}

.League table {
  margin-bottom: 1em;
}

.League thead,
th {
  font-weight: bold;
}

.League th,
td {
  padding: 15px 10px;
  display: table-cell;
  vertical-align: middle;
  border-radius: 2px;
}

.League .note {
  font-size: 0.9em;
  color: #aaa;
}

.League .table-wrapper {
  margin: 2em 0;
}

.League ul {
  line-height: 1.6;
  margin-bottom: 1em;
}

.League ul li {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 1em;
}

.League .photos-wrapper {
  margin-bottom: 1em;
}

.League .photos {
  margin-bottom: 1em;
}

.League .photos img {
  display: block;
  width: 100%;
  margin-bottom: 0.5em;
  border: 1px solid #cfd6da;
}

.League .photos-caption {
  font-size: 0.9em;
}

@media (min-width: 700px) {
  .League .photos-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .League .photos {
    width: calc(50% - 0.5em);
  }
}
