.Home {
  /*background-color: #ccc;*/
}

.Home .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home-graphic {
  width: 40%;
  position: relative;
  margin-right: 20px;
}

.Home-graphic-inset {
  width: 100%;
  padding-bottom: 90%;
  margin-right: 20px;
}

.Home-graphic-image {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Home-title {
  width: 40%;
  display: block;
}
