.Footer {
  height: auto;
  position: relative;
  color: #fff;
  background-color: #242424;
}

.Footer .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Footer-legal {
  font-size: 0.8em;
}

.Footer-social {
  display: flex;
  align-items: center;
}

.Footer-social-link {
  display: block;
  margin-left: 10px;
  padding: 5px;
}

.Footer-social img {
  display: block;
  height: 20px;
}
