.Roster {
  padding-top: 5%;
  padding-bottom: 5%;
}

.Roster h1 {
  text-align: center;
}

.Roster .wrapper {

}

.Roster-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
  position: relative;
  margin: 0 auto;
}

.Roster-item {
  width: 100px;
  margin: 20px;
}

.Roster-item-image {
  width: 100px;
  height: 126px;
}

@media (min-width: 400px) {
  .Roster-item {
    width: 120px;
    margin: 20px 30px;
  }

  .Roster-item-image {
    width: 120px;
    height: 151px;
  }
}

.Roster-item-image img {
  display: block;
  width: 100%;
}

.Roster-item-name {
  padding: 10px;
  background-color: #1fc9a3;
  color: #fff;
  text-align: center;
  width: 100%;
  border-radius: 30px;
  font-weight: 400;
  margin-top: -10px;
  position: relative;
  z-index: 10;
}
