.Header {
  height: 100%;
  position: relative;
  background-color: #fff;
}

.Header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Header .wrapper::after {
  content: '';
  display: block;
  position: absolute;
  left: -40px;
  bottom: 0;
  width: calc(100% + 80px);
  height: 2px;
  background-color: #cfd6da;
}

.Header-logo {

}

.Header-logo img {
  display: block;
  height: 40px;
}

.Header-nav {
  display: flex;
  align-items: center;
}

.Header-nav-item {
  display: block;
  margin-left: 20px;
  text-decoration: none;
  text-align: center;
  width: auto;
}

@media (min-width: 540px) {
  .Header-nav-item {
    width: 60px;
  }
}

.Header-nav-item .icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  position: relative;
}

.Header-nav-item .icon img {
  display: block;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Header-nav-item .label {
  text-align: center;
  font-size: 12px;
  color: #bebdbd;
}
