.OurStory {
  background-color: #e4e3e3;
  padding-top: 7%;
  padding-bottom: 5%;
}

.OurStory .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OurStory-title {
  display: block;
}

.OurStory-title img {
  display: none;
}

.OurStory-text {

}

.OurStory-text .hidden {
  display: none;
}

.OurStory-morelink {
  margin-top: 20px;
}

@media (min-width: 700px) {
  .OurStory .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }

  .OurStory-title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 0 0 auto;
    margin-right: 4em;
  }

  .OurStory-title img {
    display: block;
    width: 70px;
  }
}
