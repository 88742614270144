.App {
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  height: 100vh;
  overflow: hidden;
}

.App-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  overflow: hidden;
  position: relative;
  z-index: 100;
  background-color: #fff;
  transition: transform 500ms linear;
  transform: translateX(0);
  min-width: 300px;
}

.App-wrapper.opened {
  transform: translateX(-200px);
}

.App-header {
  flex: 0 0 auto;
}

.App-content {
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.App-content-inset {
  overflow-y: scroll;
  position: absolute;
  height: 100%;
  width: 100%;
}

.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  height: inherit;
  padding-left: 10px;
  padding-right: 10px;
}

.App h1 {
  font-size: 1.6em;
  line-height: 1.2;
  color: #1fc9a3;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.App p {
  line-height: 1.6;
}

.App p:not(:last-child) {
  margin-bottom: 1em;
}

.App a {
  color: #1fc9a3;
  font-weight: 400;
}

.App .mobile {
  display: block;
}

.App .desktop {
  display: none;
}

@media (min-width: 540px) {
  .App .mobile {
    display: none;
  }

  .App .desktop {
    display: block;
  }
}
