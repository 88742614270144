.App-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 200px;
  color: #fff;
  background-color: #242424;
  padding-top: 10px;
  padding-bottom: 10px;
}

.App-nav .Header-nav-item {
  padding: 5px 10px;
  text-align: center;
  width: 100%;
  margin-left: 0;
  margin-bottom: 10px;
}

.App-nav .Header-nav-item .label {
  font-size: 14px;
}
